import { motion } from "framer-motion";

interface KeyDisplayProps {
  keyName: string;
  isPressed: boolean;
  isAkkusativMode: boolean;
  onClick?: () => void;
}

export const KeyDisplay: React.FC<KeyDisplayProps> = ({ keyName, isPressed, isAkkusativMode, onClick }) => (
  <motion.div
    initial={{ scale: 1 }}
    animate={{ scale: isPressed ? 0.9 : 1 }}
    className={`w-16 h-16 rounded-lg border-2 flex items-center justify-center cursor-pointer ${
      isPressed ? 'bg-gray-200 border-gray-400' : 'bg-white border-gray-200'
    } transition-colors`}
    onClick={onClick}
    role="button"
    tabIndex={0}
    aria-label={`Select article ${
      keyName === 'ArrowLeft' ? (isAkkusativMode ? 'den' : 'der') :
      keyName === 'ArrowUp' ? 'die' :
      keyName === 'ArrowRight' ? 'das' :
      keyName === 'ArrowDown' ? 'skip' : ''
    }`}
  >
    <span className="text-base font-medium">
      {keyName === 'ArrowLeft' ? (isAkkusativMode ? 'den' : 'der') :
       keyName === 'ArrowUp' ? 'die' :
       keyName === 'ArrowRight' ? 'das' :
       keyName === 'ArrowDown' ? 'skip' : ''}
    </span>
  </motion.div>
);