import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "../../../components/ui/button";
import { Card, CardContent } from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { KeyDisplay } from "./KeyDisplay";
import { navigationItems, fontOptions, defaultWord, shuffleArray } from "../constants";
import { GermanWord, AkkusativSentence } from "../../../data/words";

const variants = {
  enter: (direction: number) => ({
    y: direction > 0 ? 100 : -100,
    opacity: 0,
    scale: 0.8
  }),
  center: {
    y: 0,
    opacity: 1,
    scale: 1,
    zIndex: 1
  },
  exit: (direction: number) => ({
    y: direction < 0 ? 100 : -100,
    opacity: 0,
    scale: 0.8,
    zIndex: 0
  })
};

const tableVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.3,
      ease: "easeIn"
    }
  }
};

interface GameProps {
  activeNav: number;
  showTable: boolean;
  selectedFont: string;
  autoCheck: boolean;
}

export const Game: React.FC<GameProps> = ({
  activeNav,
  showTable,
  selectedFont,
  autoCheck,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userArticle, setUserArticle] = useState("");
  const [direction, setDirection] = useState(0);
  const [currentWords, setCurrentWords] = useState<(GermanWord | AkkusativSentence)[]>([defaultWord]);
  const [showResult, setShowResult] = useState(false);
  const [pressedKey, setPressedKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const isAkkusativMode = activeNav === 3;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading, currentIndex, activeNav]);

  useEffect(() => {
    if (!autoCheck) {
      setShowResult(false);
    }
  }, [autoCheck]);

  useEffect(() => {
    const checkWordsLoaded = () => {
      const selectedNavItem = navigationItems.find(item => item.id === activeNav);
      if (selectedNavItem && selectedNavItem.words.length > 0) {
        setCurrentWords(shuffleArray(selectedNavItem.words));
        setIsLoading(false);
      } else {
        setTimeout(checkWordsLoaded, 100);
      }
    };

    setIsLoading(true);
    checkWordsLoaded();
  }, [activeNav]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(e.key)) {
        e.preventDefault();
        setPressedKey(e.key);
        
        if (e.key === 'ArrowDown') {
          handleNext();
          return;
        }
        
        const articleMap = {
          'ArrowLeft': isAkkusativMode ? 'den' : 'der',
          'ArrowUp': 'die',
          'ArrowRight': 'das'
        };
        
        const article = articleMap[e.key as keyof typeof articleMap];
        if (article) {
          setUserArticle(article);
          handleArticleSubmit(article);
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(e.key)) {
        setPressedKey(null);
      }
    };

    if (!isLoading) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [currentIndex, isLoading, activeNav, isAkkusativMode]);

  const handleNext = () => {
    if (isLoading) return;
    setDirection(1);
    setCurrentIndex((prev) => (prev + 1) % currentWords.length);
    setUserArticle("");
    setShowResult(false);
  };

  const handlePrevious = () => {
    if (isLoading) return;
    setDirection(-1);
    setCurrentIndex((prev) => (prev - 1 + currentWords.length) % currentWords.length);
    setUserArticle("");
    setShowResult(false);
  };

  const handleArticleSubmit = (article?: string) => {
    if (isLoading) return;
    
    const currentArticle = article || userArticle;
    if (!currentArticle) return;
    
    const currentItem = currentWords[currentIndex];
    const isCorrect = currentArticle.toLowerCase() === currentItem.article.toLowerCase();
    
    setShowResult(true);
    if (isCorrect) {
      setTimeout(() => {
        handleNext();
      }, 500);
    }
  };

  const handleArticleChange = (value: string) => {
    setUserArticle(value);
    if (autoCheck) {
      handleArticleSubmit(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleArticleSubmit();
    }
  };

  const handleArticleKeyPress = (keyName: string) => {
    // Simulate key press effect
    setPressedKey(keyName);
    setTimeout(() => setPressedKey(null), 150);
    
    if (keyName === 'ArrowDown') {
      handleNext();
      return;
    }
    
    const articleMap = {
      'ArrowLeft': isAkkusativMode ? 'den' : 'der',
      'ArrowUp': 'die',
      'ArrowRight': 'das'
    };
    
    const article = articleMap[keyName as keyof typeof articleMap];
    if (article) {
      setUserArticle(article);
      handleArticleSubmit(article);
    }
  };

  const getCurrentContent = () => {
    if (currentWords.length === 0) return defaultWord;
    
    const currentItem = currentWords[currentIndex];
    if (!currentItem) return defaultWord;

    if ('sentence' in currentItem) {
      return {
        english: currentItem.englishMeaning,
        german: currentItem.firstPart + currentItem.lastPart,
        article: currentItem.article,
        isAkkusativ: true,
        firstPart: currentItem.firstPart,
        lastPart: currentItem.lastPart
      };
    }

    return { ...currentItem, isAkkusativ: false };
  };

  const currentWord = getCurrentContent();

  return (
    <AnimatePresence mode="wait" custom={direction}>
      {showTable ? (
        <motion.div
          key="table"
          variants={tableVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{ perspective: 2000 }}
        >
          <Card className="overflow-hidden rounded-2xl">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-gray-900">English</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-gray-900">German</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-gray-900">Article</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {currentWords.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 text-sm text-gray-900">
                      {'sentence' in item ? item.englishMeaning : item.english}
                    </td>
                    <td className="px-6 py-4 text-sm font-semibold text-gray-900">
                      {'sentence' in item ? item.sentence : item.german}
                    </td>
                    <td className="px-6 py-4 text-sm font-semibold text-gray-900">{item.article}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </motion.div>
      ) : (
        <motion.div
          key={currentIndex}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 500, damping: 30 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 }
          }}
          className="relative"
          style={{ perspective: 2000 }}
        >
          <Card className="mt-4 h-[calc(100vh-16rem)] rounded-2xl">
            <CardContent className="flex h-full flex-col items-center justify-center p-0 relative">
              <div className="text-center">
                <p className={`mb-4 sm:mb-6 text-3xl sm:text-4xl lg:text-6xl italic text-gray-600 ${
                  fontOptions.find(f => f.value === selectedFont)?.className
                }`}>
                  {currentWord.english}
                </p>
                {'isAkkusativ' in currentWord && currentWord.isAkkusativ ? (
                  <div className={`text-[48px] sm:text-[64px] lg:text-[90px] font-bold text-gray-900 ${
                    fontOptions.find(f => f.value === selectedFont)?.className
                  }`}>
                    <span>{currentWord.firstPart}</span>
                    <Input
                      ref={inputRef}
                      type="text"
                      value={userArticle}
                      onChange={(e) => handleArticleChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      className={`article-input w-40 text-center inline-block mx-2 ${
                        fontOptions.find(f => f.value === selectedFont)?.className
                      } ${
                        showResult
                          ? userArticle.toLowerCase() === currentWord.article.toLowerCase()
                            ? "correct"
                            : "incorrect"
                          : ""
                      }`}
                      placeholder=""
                      disabled={isLoading}
                    />
                    <span>{currentWord.lastPart}</span>
                  </div>
                ) : (
                  <div className="word-container justify-center">
                    <div className="relative">
                      <Input
                        ref={inputRef}
                        type="text"
                        value={userArticle}
                        onChange={(e) => handleArticleChange(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className={`article-input w-40 text-center ${
                          fontOptions.find(f => f.value === selectedFont)?.className
                        } ${
                          showResult
                            ? userArticle.toLowerCase() === currentWord.article.toLowerCase()
                              ? "correct"
                              : "incorrect"
                            : ""
                        }`}
                        placeholder=""
                        disabled={isLoading}
                      />
                    </div>
                    <span className={`text-[48px] sm:text-[64px] lg:text-[90px] font-bold text-gray-900 ${
                      fontOptions.find(f => f.value === selectedFont)?.className
                    }`}>
                      {currentWord.german}
                    </span>
                  </div>
                )}
                
                {/* Fixed height container for feedback message */}
                <div className="h-16 flex items-center justify-center">
                  {showResult && (
                    <motion.p
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={`text-xl font-semibold ${
                        userArticle.toLowerCase() === currentWord.article.toLowerCase()
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {userArticle.toLowerCase() === currentWord.article.toLowerCase()
                        ? "Correct! 🎉"
                        : "Wrong! Try again"}
                    </motion.p>
                  )}
                </div>
                
                <div className="mt-2 flex justify-center">
                  <div className="grid grid-cols-3 gap-3 w-fit">
                    <div className="col-start-2">
                      <KeyDisplay 
                        keyName="ArrowUp" 
                        isPressed={pressedKey === 'ArrowUp'} 
                        isAkkusativMode={isAkkusativMode}
                        onClick={() => handleArticleKeyPress('ArrowUp')}
                      />
                    </div>
                    <div className="col-start-1 col-end-4 grid grid-cols-3 gap-3">
                      <KeyDisplay 
                        keyName="ArrowLeft" 
                        isPressed={pressedKey === 'ArrowLeft'} 
                        isAkkusativMode={isAkkusativMode}
                        onClick={() => handleArticleKeyPress('ArrowLeft')}
                      />
                      <KeyDisplay 
                        keyName="ArrowDown" 
                        isPressed={pressedKey === 'ArrowDown'} 
                        isAkkusativMode={isAkkusativMode}
                        onClick={() => handleArticleKeyPress('ArrowDown')}
                      />
                      <KeyDisplay 
                        keyName="ArrowRight" 
                        isPressed={pressedKey === 'ArrowRight'} 
                        isAkkusativMode={isAkkusativMode}
                        onClick={() => handleArticleKeyPress('ArrowRight')}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute bottom-8 left-8">
                <Button
                  onClick={handlePrevious}
                  className="h-12 w-12 rounded-full bg-[#444444] p-0 shadow-sm transition-all hover:bg-[#333333]"
                  disabled={isLoading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m15 18-6-6 6-6"/>
                  </svg>
                </Button>
              </div>
              <div className="absolute bottom-8 right-8">
                <Button
                  onClick={handleNext}
                  className="h-12 w-12 rounded-full bg-[#444444] p-0 shadow-sm transition-all hover:bg-[#333333]"
                  disabled={isLoading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6"/>
                  </svg>
                </Button>
              </div>
            </CardContent>
          </Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
};