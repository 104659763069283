import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import { LandingPreview } from '../../components/LandingPreview';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { supabase } from '../../lib/supabase';

const navItems = [
  { name: 'Features', href: '#features' },
  { name: 'How It Works', href: '#how-it-works' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Waitlist', href: '#waitlist' },
];

interface UserRole {
  roles: {
    name: string;
  };
}

export const Landing = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleAuth = async () => {
      try {
        // Check for OAuth callback
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        
        if (code) {
          console.log('[Landing] Found auth code, exchanging for session');
          const { data: exchangeData, error: exchangeError } = await supabase.auth.exchangeCodeForSession(code);
          
          if (exchangeError) {
            console.error('[Landing] Exchange error:', exchangeError);
            return;
          }

          if (!exchangeData.session?.user) {
            console.error('[Landing] No user in exchanged session');
            return;
          }

          const user = exchangeData.session.user;
          console.log('[Landing] Session established for user:', user.id);

          // Check/create profile
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', user.id)
            .single();

          if (profileError && profileError.code === 'PGRST116') {
            console.log('[Landing] Creating new profile');
            await supabase.from('profiles').insert({
              id: user.id,
              full_name: user.user_metadata.full_name || user.user_metadata.name,
              avatar_url: user.user_metadata.avatar_url,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString()
            });
          }

          // Check role
          const { data: userRole, error: roleError } = await supabase
            .from('user_roles')
            .select('roles(name)')
            .eq('user_id', user.id)
            .maybeSingle() as { data: UserRole | null, error: any };

          console.log('[Landing] Role check:', { userRole, roleError });

          if (!userRole || (roleError && roleError.code === 'PGRST116')) {
            console.log('[Landing] No role found, redirecting to selection');
            navigate('/select-role', { replace: true });
          } else if (userRole?.roles?.name === 'teacher') {
            console.log('[Landing] Teacher role found, redirecting to dashboard');
            navigate('/teacher-dashboard', { replace: true });
          } else {
            console.log('[Landing] Student role found, redirecting to app');
            navigate('/app', { replace: true });
          }
        }
      } catch (error) {
        console.error('[Landing] Auth error:', error);
      }
    };

    handleAuth();
  }, [navigate]);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="landing-page">
      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm border-b border-gray-200">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center gap-8">
              <Link to="/" className="flex items-center">
                <img
                  src="/deutschlylogo-1.png"
                  alt="Deutschly"
                  className="h-6 w-auto"
                />
              </Link>
              <div className="hidden md:flex items-center gap-6">
                {navItems.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => scrollToSection(item.href.slice(1))}
                    className="text-sm font-medium text-gray-600 hover:text-gray-900"
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Link to="/signin">
                <Button variant="ghost" size="sm">Sign in</Button>
              </Link>
              <Link to="/waitlist">
                <Button size="sm">Join Waitlist</Button>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="relative pt-16 pb-8 bg-gradient-to-b from-gray-50 to-white">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_#E4E4E7_0%,_transparent_50%)]"></div>
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_#F4F4F5_0%,_transparent_50%)]"></div>
        </div>
        
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-16">
          <div className="text-center mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-3xl sm:text-4xl font-bold mb-3">
                Master German Grammar
                <br />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
                  Without the Headache
                </span>
              </h1>
            </motion.div>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="text-base sm:text-lg text-gray-600 max-w-xl mx-auto mb-4 whitespace-nowrap"
            >
              Finally understand Articles, Cases, and those annoying grammar rules.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex justify-center gap-4"
            >
              <Link to="/waitlist">
                <Button size="default" className="rounded-full px-4 py-2 text-sm bg-black hover:bg-gray-900">
                  Join the Waitlist
                </Button>
              </Link>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.3 }}
            className="relative mx-auto max-w-6xl"
          >
            <div 
              className="aspect-[1920/935] overflow-hidden rounded-lg bg-transparent" 
              style={{ 
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                border: 'none' 
              }}
            >
              <img
                src="/Dashboard.png"
                alt="Deutschly Dashboard"
                className="w-full h-full object-cover rounded-lg"
                style={{ display: 'block' }}
              />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Interactive Preview Section */}
      <section id="how-it-works" className="py-16 bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">
                Grammar Practice That Actually Sticks
              </h2>
              <div className="space-y-4">
                {[
                  { text: "Adaptive grammar drills that get smarter as you go" },
                  { text: "Master articles with targeted article training" },
                  { text: "Teacher Dashboard to track progress" },
                  { text: "Practice Nominativ, Akkusativ, Dativ & Genitiv" },
                  { text: "Built for real retention, not just passive review" }
                ].map((feature, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#2B2B2B" stroke="none" className="tabler-icon tabler-icon-circle-check-filled">
                      <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path>
                    </svg>
                    <span className="text-base text-gray-700">{feature.text}</span>
                  </div>
                ))}
              </div>
              <div className="mt-6">
                <Link to="/waitlist">
                  <Button size="default" variant="outline">See How It Works</Button>
                </Link>
              </div>
            </div>
            <div className="h-[600px] relative bg-[#111111] rounded-xl overflow-hidden shadow-xl">
              <div className="absolute inset-0">
                <LandingPreview />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-16 bg-gray-50">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Built for Real Progress, Not Just Practice</h2>
          </div>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              {
                title: "Easy Integration with Syllabi",
                description: "Allows teachers to upload custom content and assign their own material to students"
              },
              {
                title: "Multiple Modes",
                description: "From cases, to vocabulary, to assigned homework and exam preparation"
              },
              {
                title: "Teacher Dashboard",
                description: "Let instructors assign decks, track progress, and upload custom content (Coming Soon)"
              }
            ].map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="#2B2B2B" stroke="none" className="tabler-icon tabler-icon-circle-check-filled">
                    <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path>
                  </svg>
                </div>
                <h3 className="text-lg font-bold mb-2">{feature.title}</h3>
                <p className="text-sm text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Teacher Dashboard Showcase Section */}
      <section className="py-16 bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Powerful Teacher Dashboard</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Track student progress, manage assignments, and gain insights into your class performance with our comprehensive teacher dashboard.
            </p>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            viewport={{ once: true }}
            className="relative rounded-2xl overflow-hidden shadow-md"
          >
            <img
              src="/teacherDashboard.png"
              alt="Teacher Dashboard Interface"
              className="w-full rounded-2xl"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/10 via-transparent to-transparent rounded-2xl"></div>
          </motion.div>
          <div className="mt-12 grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Real-time Analytics",
                description: "Monitor class performance and individual student progress with detailed analytics and insights."
              },
              {
                title: "Assignment Management",
                description: "Create, assign, and track homework assignments with ease. Set due dates and monitor completion rates."
              },
              {
                title: "Student Progress Tracking",
                description: "View detailed progress reports and identify areas where students need additional support."
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white p-6 rounded-xl shadow-sm"
              >
                <div className="mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="#2B2B2B" stroke="none" className="tabler-icon tabler-icon-circle-check-filled">
                    <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path>
                  </svg>
                </div>
                <h3 className="text-lg font-bold mb-2">{feature.title}</h3>
                <p className="text-sm text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-16 bg-gray-50">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-3">Free During Beta</h2>
            <p className="text-lg text-gray-600 mb-6">
              Early access users get full features, forever free.
            </p>
            <Link to="/waitlist">
              <Button>Get Early Access</Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="py-16 bg-black text-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-3">Ready to Finally Understand German Grammar?</h2>
            <p className="text-lg mb-6">Join others on the waitlist today.</p>
            <Link to="/waitlist">
              <Button className="bg-white text-black hover:bg-gray-100">
                Join Waitlist
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white py-8 border-t border-gray-200">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div>
              <img
                src="/deutschlylogo-1.png"
                alt="Deutschly"
                className="h-6 w-auto mb-4"
              />
              <p className="text-sm text-gray-600">
                Making German grammar intuitive and enjoyable to learn.
              </p>
            </div>
            <div>
              <h3 className="font-semibold mb-3 text-sm">Product</h3>
              <ul className="space-y-2 text-sm">
                <li><Link to="#features" className="text-gray-600 hover:text-gray-900">Features</Link></li>
                <li><Link to="#pricing" className="text-gray-600 hover:text-gray-900">Pricing</Link></li>
                <li><Link to="/waitlist" className="text-gray-600 hover:text-gray-900">Waitlist</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-3 text-sm">Company</h3>
              <ul className="space-y-2 text-sm">
                <li><Link to="/about" className="text-gray-600 hover:text-gray-900">About</Link></li>
                <li><Link to="/blog" className="text-gray-600 hover:text-gray-900">Blog</Link></li>
                <li><a href="mailto:hello@deutschly.com" className="text-gray-600 hover:text-gray-900">Contact</a></li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-3 text-sm">Legal</h3>
              <ul className="space-y-2 text-sm">
                <li><Link to="/privacy" className="text-gray-600 hover:text-gray-900">Privacy</Link></li>
                <li><Link to="/terms" className="text-gray-600 hover:text-gray-900">Terms</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-200 text-center text-sm text-gray-600">
            <p>© {new Date().getFullYear()} Deutschly. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};